/*
TOOL ONLINE PER verificare AES 
https://www.devglan.com/online-tools/aes-encryption-decryption
*/
import crypto from 'crypto'

class aes  {
    constructor () {
        this.password = this.convertCryptKey('teraslapassword')
    } 
    convertCryptKey(strKey) {
        const newKey = new Buffer([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
        strKey = new Buffer(strKey)
        for (let i = 0; i < strKey.length; i++) newKey[i % 16] ^= strKey[i]
        return newKey
    }
    encode(value){
        let password  = (arguments.length>1) ? this.convertCryptKey(arguments[1].toString()) : this.password
        if (typeof value !== 'string') {
            value = JSON.stringify(value)
        }

        const c = crypto.createCipheriv('aes-128-ecb', password, '')
        let ret = ''
        ret += c.update(value, 'utf8', 'hex')
        ret += c.final('hex')
        return ret.toUpperCase()
    }
    decode(value){
        let password  = (arguments.length>1) ? this.convertCryptKey(arguments[1].toString()) : this.password
        let ret = ''
        try{
            const d = crypto.createDecipheriv('aes-128-ecb', password, '')
            ret += d.update(value, 'hex', 'utf8')
            ret += d.final('utf8')
        }catch(e){
            ret = value
        }
        return ret
    }   
    encode64(value){
        let password  = (arguments.length>1) ? this.convertCryptKey(arguments[1].toString()) : this.password
        if (typeof value !== 'string') {
            value = JSON.stringify(value)
        }
        
        const c = crypto.createCipheriv('aes-128-ecb', password, '')
        let ret = ''
        ret += c.update(value, 'utf8', 'base64')
        ret += c.final('base64')
        return ret
    }
    decode64(value){
        let password  = (arguments.length>1) ? this.convertCryptKey(arguments[1].toString()) : this.password
        let ret = ''
        try{
            const d = crypto.createDecipheriv('aes-128-ecb', password, '')
            ret += d.update(value, 'base64', 'utf8')
            ret += d.final('utf8')
        }catch(e){
            ret = value
        }
        return ret
    }   

}

export default new aes
