<template>
   <div v-loading.fullscreen.lock="!$teras.app.ready" :class="'device-'+$teras.app.device+' route-'+$route.name">

      <site-header v-if="![ 'landing', 'landing2' ].includes($route.name)" />

      <div class="app-main row">
         <div id="main" class="col s12">
            <router-view name="main"></router-view>
         </div><!-- end main -->
      </div><!-- end container -->
      
      <site-footer v-if="![ 'landing', 'landing2' ].includes($route.name)" />
   </div> <!-- end app -->
</template>

<script>
import { defineAsyncComponent } from "vue"
export default {
   name: 'app',
   components: {
      SiteHeader: defineAsyncComponent(() => import("@/components/Site-header.vue")),
      SiteFooter: defineAsyncComponent(() => import("@/components/Site-footer.vue")),
   },
   data() {
      return {
         loading:{}
      }
   },
   created(){

      this.$teras.event.on('notify', (titolo,messaggio,tipo) => {
         titolo = (typeof messaggio == 'string') ? titolo : 'Errore non specificato'
         messaggio = (typeof messaggio == 'string') ? messaggio : 'Siamo spiacenti si è verificato un errore, la preghiamo di verificare i dati inseriti!'
         tipo = (typeof tipo == 'string') ? tipo : 'error'
         this.$notify({type: tipo, title: titolo, message: messaggio})
      })

      this.$teras.event.on('loading', (messaggio) => {
         console.log(typeof messaggio)
         messaggio = (typeof messaggio == 'string') ? messaggio : 'Caricamente in corso, non ricaricare la pagina.....'
         console.log("messaggio: "+messaggio)
         this.loading = this.$loading({lock: true, text: messaggio, spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' })
      })
      this.$teras.event.on('loadingclose', () => {
         this.loading.close()
      })
   }
}
</script>

<style>

</style>
