import { createWebHistory, createWebHashHistory, createRouter } from "vue-router";

/*const Landing = () => import("@/components/Landing");
const Landing2 = () => import("@/components/Landing-2");*/

const Home = () => import("@/components/Home");
const Aree = () => import("@/components/Aree");
const News = () => import("@/components/News");
const PrivacyPolicy = () => import("@/components/Privacy-policy");

var mode = (process.env.NODE_ENV != "development") ? createWebHistory("/") : createWebHashHistory("/")

export const createVueRouter = (app) => {
   const router = createRouter({
      history: mode,

      scrollBehavior (to, from, savedPosition) {
         document.getElementById("app").scrollIntoView({ behavior: "smooth" });
      },

      routes: [
         /*{ path: "/landing", name: "landing", meta: { requiresEvent: false, requiresAuth: false }, components:{main: Landing }},
         { path: "/landing2", name: "landing2", meta: { requiresEvent: false, requiresAuth: false }, components:{ main: Landing2 }},*/

         { path: "/", name: "home", meta: { requiresEvent: false, requiresAuth: false, title: "VD Legal" }, components: { main: Home }},
         { path: "/specializzazioni", name: "specializzazioni", meta: { requiresEvent: false, requiresAuth: false, title: "Specializzazioni" }, components: { main: Aree }},
         { path: "/news", name: "news", meta: { requiresEvent: false, requiresAuth: false, title: "News" }, components: { main: News }},
         //{ path: "/contatti", name: "contatti", meta: { requiresEvent: false, requiresAuth: false, title: "Contatti" }, components: { main: Contatti }},
         { path: "/privacy-policy", name: "contatti", meta: { requiresEvent: false, requiresAuth: false, title: "Privacy Policy" }, components: { main: PrivacyPolicy }},

         { path: "/:catchAll(.*)", redirect: "/" } 
      ]
   })

   router.beforeEach(async (to, from, next) => {
      var teras = app.config.globalProperties.$teras
      let error = false
      teras.app.drawer = false
      //to.query contiene le variabili passate in modo standard es. ?variabile=valore
      //to.params contiene le variabili passate in vuejs passate in base a routes[]
      console.log("\nbeforeEach \ncheckFirst: "+router.checkFirst+"\nfrom: "+from.path+"\nto: "+to.path+"\n\n")
      teras.router.from = from.path
      teras.router.to = to.path

      if (!teras.check.first){
         console.log("SONO RICHIESTE LE OPERAZIONI DI PRIMO AVVIO")
         teras.event.emit("checkFirst", from, to)
         error= true;
      }
      
      console.log("AUTH "+teras.app.auth +" AND error "+error)
      if (!error && to.matched.some(record => record.meta.requiresAuth) && !teras.check.auth){
         console.log("È RICHIESTO UTENTE E NON È ANCORA VERIFICATO")
         teras.event.emit("checkAuth", from, to)
         error = true
      }
    
      if(error){
         next(false)
      }else{
         console.log("beforeEach next")
         window.scrollTo(0,0)
         teras.app.ready = true
         if(to.name=="home"){
            document.title= to.meta.title
         } else{
            document.title= to.meta.title+" | "+teras.defaultPageTitle
         }
         next()
         teras.event.emit("routerChange", to)
      }
   })

   return router
}
