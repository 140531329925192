import { createApp } from 'vue/dist/vue.esm-bundler'; 
import {h, reactive} from 'vue'
import App from './App.vue'


/* Inizio Router */
import { createVueRouter } from './router'
/* Fine Router */

/* Inizio ElementPlus */
import { ElLoading, ElMessage, ElMessageBox, dayjs } from 'element-plus';
//import 'element-plus/theme-chalk/index.css';
//Importa il primo giorno della settimana nei picker al Lunedì
dayjs().$locale().weekStart = 1;
import "./css/element-var.css"
/* Fine ElementPlus */

/* Inizio Luxon */
//https://moment.github.io/luxon/#/moment
//https://moment.github.io/luxon/#/formatting?id=table-of-tokens
import { DateTime as luxon, Settings as luxonSettings } from "luxon"
luxonSettings.defaultLocale= "it"
luxonSettings.defaultZone= "Europe/Rome"
/* Fine Luxon */

/* Inizio TerAs */
import terasImp from './teras'
var teras= reactive(terasImp)
import axios from './teras/tAxios'
import storage from './teras/tStorage' 

import './css/big.css'
/* Fine TerAs */

/* Inizio Global Function */
teras.event.on("checkFirst", async function(from, to) {   
   teras.check.first = true
   //if(router.options.history.location!=to.path)
      router.replace(to.path)
})
/* Fine Global Function */

/* Inizio TerAs-event */
teras.event.on('checkAuth', async (from,to) =>{
   console.log("CHECK AUTH")
   if(storage.exist('utente')){
      console.log("YES, PRESENTE UTENTE IN LOCAL STORAGE....LO RICARICO DA LI..."+to.path)
      teras.utente = storage.load('utente')
      teras.event.emit('login',to.path)
   }else{
      teras.check.auth = false
      teras.event.emit('errore',-1,'È necessario effettuare l\'accesso prima di poter accedere alla pagina')
      if(from.path!='/login')
         router.replace('/login')
   }
})
teras.event.on('appReady', function() {
   console.log("appReady appReady appReady appReady appReady appReady appReady")
   router.push(teras.app.routeInitial)
})
teras.event.on('appExit', function() {
})

teras.event.on('setSeoInformation', async function(page){
   new Promise((resolve) => {
      teras.event.emit('removeSeoInformationFromPrevPage', resolve)
   }).then(async (value) => {
      //Imposto il meta title e description per la pagina statica recuperata
      for(var i=0; i<page.head.children.length; i++){
         if(page.head.children[i].attributes[0].nodeName=='title'){
            console.log('SEO title: ' +page.head.children[i].attributes[0].nodeValue)
            var meta = document.createElement('meta');
            meta.title= page.head.children[i].attributes[0].nodeValue
            document.getElementsByTagName('head')[0].appendChild(meta);
         }
         if(page.head.children[i].attributes[0].nodeName=='name' && page.head.children[i].attributes[0].nodeValue=='description' && 
            page.head.children[i].attributes[1]!=undefined && page.head.children[i].attributes[1].nodeName=='content'){
            console.log('SEO description: ' +page.head.children[i].attributes[1].nodeValue)
            var meta = document.createElement('meta');
            meta.name='description'
            meta.content = page.head.children[i].attributes[1].nodeValue
            document.getElementsByTagName('head')[0].appendChild(meta);
         }
      }
   })
})
teras.event.on('removeSeoInformationFromPrevPage', function(callback){
   //Salvo dentro l'array nodeToRemove i tag meta della pagina statica precedente che sono da rimuovere per inserire quelli nuovi
   var nodeToRemove= []
   for(var i=0; i<document.getElementsByTagName('head')[0].children.length; i++){
      var node= document.getElementsByTagName('head')[0].children[i]
      if(node.localName=='meta' && node.attributes[0].nodeName=='title'){
         nodeToRemove.push(node)
      }
      if(node.localName=='meta' && node.name=='description'){
         nodeToRemove.push(node)
      }
   }
   for(var i=0; i<nodeToRemove.length; i++){
      document.getElementsByTagName('head')[0].removeChild(nodeToRemove[i])
   }
   if(callback!=undefined){
      //console.log('callback')
      callback(true)
   }
})

window.addEventListener('resize',() => { 
   teras.getDevice()
   teras.event.emit('appResize')
})
window.addEventListener('scroll',() => { 
   teras.getDevice()
   teras.event.emit('appScroll')
})

/* Fine TerAs-event */

/* Inizio Vue-3 */

const app= createApp({
   /*data() {
      return vueData
   },*/
   render: () => h(App)
})

app.config.globalProperties.$teras= teras
app.config.globalProperties.$axios = axios
app.config.globalProperties.$luxon = luxon
app.config.globalProperties.$storage = storage

const router = createVueRouter(app)
app.use(router)
app.use(ElLoading)
app.use(ElMessage)
app.use(ElMessageBox)

app.mount('#app')

teras.vueAppInstance= app

/* Fine Vue-3 */
