const { detect } = require('detect-browser')
//var accounting = require('accounting')
const EventEmitter = require('events').EventEmitter

import { createApp } from 'vue/dist/vue.esm-bundler'; 
import storage from './tStorage'

import { DateTime as luxon, Settings as luxonSettings } from "luxon"
luxonSettings.defaultLocale= "it"
luxonSettings.defaultZone= "Europe/Rome"

export class main{
   constructor (args) {
      this.event = new EventEmitter()
      this.luxon = luxon

      this.vueAppInstance
      
      this.ttlMax = 10
      this.log = (process.env.NODE_ENV != 'development') ? false : true

      this.check = {
         first: false,
         event: false,
         auth: false,
         gruppo: false
      }
      this.app = {
         ready: false
         ,step: -1
         ,auth:false
         ,routeInitial: '/'
         ,routeAuth: '/'
         ,routeNoauth: '/login'
         ,routeError: '/error'
         ,device: 'desktop'
         ,os: null
         ,browsername: null
         ,browserversion: null
         ,width: 0
         ,height: 0
         //,scroll: 'no-scroll'
         ,scroll: 0
         ,drawer: false
      }

      this.browser = detect()
      if (this.browser) {
         this.app.os = this.browser.os
         this.app.browsername = this.browser.name
         this.app.browserversion = this.browser.version
         console.log(this.browser.name);
         console.log(this.browser.version);
         console.log(this.browser.os);
      }

      this.router = {
         from: this.app.routeInitial
         ,to: this.app.routeInitial
         ,backtime: 0
      }

      if(!this.log)
         console.log = function() {}
      return this
   }
   
   install(Vue) {
      Vue.prototype.$teras = this
   }

   init() {
      this.getDevice()
      
      window.addEventListener('beforeunload', () => {
         storage.save('ttl',this.luxon.now().toFormat('yyyy-MM-dd HH:mm:ss'))
         this.event.emit('appExit')
      })
      /*window.addEventListener('resize',() => { 
         this.getDevice()
         this.event.emit('appResize')
      })*/
      /*window.addEventListener('scroll',() => { 
         this.getDevice()
         this.event.emit('appScroll')
      })*/

      //Verifico se la app è ready in base alle regole specifiche di ogni app
      this.readyInterval = setInterval(() => { 
         if(this.app.step>-1){
            this.app.ready = true
            clearInterval(this.readyInterval)
            this.event.emit('appReady')
         }
      }, 500)
      
      return this
   }
   ttl(){
      var isover = false
      //if(storage.exist('ttl') && this.moment.duration(this.moment().diff(this.moment(storage.load('ttl')))).asMinutes() > this.ttlMax)
         //isover = true
      return isover
   }
   random() {
    return Math.floor((Math.random() * 10000) + 1)
   }

   objmerge(){
      var objReturn = {}
      var prop
      if(arguments.length>0) for (prop in arguments[0]) objReturn[prop] = arguments[0][prop]
      if(arguments.length>1) for (prop in arguments[1]) objReturn[prop] = arguments[1][prop]
      if(arguments.length>2) for (prop in arguments[2]) objReturn[prop] = arguments[2][prop]
      if(arguments.length>3) for (prop in arguments[3]) objReturn[prop] = arguments[3][prop]
      if(arguments.length>4) for (prop in arguments[4]) objReturn[prop] = arguments[4][prop]
      if(arguments.length>5) for (prop in arguments[5]) objReturn[prop] = arguments[5][prop]
      if(arguments.length>6) for (prop in arguments[6]) objReturn[prop] = arguments[6][prop]
      if(arguments.length>7) for (prop in arguments[7]) objReturn[prop] = arguments[7][prop]
      if(arguments.length>8) for (prop in arguments[8]) objReturn[prop] = arguments[8][prop]
      if(arguments.length>9) for (prop in arguments[9]) objReturn[prop] = arguments[9][prop]
      return objReturn
  }

   /*compareValues(key, order='asc') {
      return function(a, b) {
         if(!Object.prototype.hasOwnProperty.call(a,key) || !Object.prototype.hasOwnProperty.call(b,key) ) {
         // property doesn't exist on either object
         return 0
         }
   
         const varA = (typeof a[key] === 'string') ? 
         a[key].toUpperCase() : a[key]
         const varB = (typeof b[key] === 'string') ? 
         b[key].toUpperCase() : b[key]
   
         let comparison = 0
         if (varA > varB) {
         comparison = 1
         } else if (varA < varB) {
         comparison = -1
         }
         return (
         (order == 'desc') ? (comparison * -1) : comparison
         )
      }
   }

   yesno(value,vuoto='n.d.'){
      return (typeof value !='undefined' && value==1) ?  'yes' : 'no'
   }

   sevuoto(value,vuoto='n.d.'){
      return (typeof value !='undefined' && value.toString().length>0) ?  value : vuoto
   }

   items(value,items,campo){
      var campoFind = []
      if(typeof value =='undefined')
         return "NON TROVATO"

      if(typeof items !='undefined' && items.length>0)
         var values = value.toString().split(',')
         for (let item of items){
            if(values.indexOf(item.id.toString())>-1) 
               campoFind.push(item[campo])
         }
         
      return campoFind.toString()
   }

   findinset(value,values){
      return (values.toString().split(',').indexOf(value.toString())>-1) ? true : false
   }

   ore (value) {
      return parseInt(value/60)+this.luxon.fromISO("2001-01-01 00:"+(value % 60)+":00").toFormat("mm")
   }
   
   data (value,formato="dd-MM-yyyy") {
      return this.luxon.fromISO(value).toFormat(formato)
   }

   money (value,moneta='EUR') {
         return accounting.formatMoney(value, moneta, 2, ".", ",")
   }

   fromNow (value,formato) {
      return this.luxon.fromISO(value).startOf(formato).toRelative()
   }

   maxlenght(value,length=50,dot='...'){
      return (value.length>length) ? value.substr(0,length)+dot : value
   }

   isEmail(value){
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return (value.trim()!='' && re.test(value.trim())) 
   }*/
   
   getItemFromArrayObject(value,campo,items){
      var filterRow = {}
      if(typeof value =='undefined')
         return "NON TROVATO"

      if(typeof items !='undefined' && items.length>0){
         for (let item of items)
            if(item[campo]==value){ 
               filterRow = item
               break
            }
      }
      return filterRow
   }

  /* FUNZIONI PER RESPONSIVE LAYOUT */
  getDevice(){
      var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      if(w<500){
      this.app.device = 'mobile'
      }else if(w<1024){
      this.app.device = 'tablet'
      }else{
      this.app.device = 'desktop'
      }
      this.app.width = w
      this.app.height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

      var distanceY = window.pageYOffset || document.documentElement.scrollTop
      this.app.scroll = (distanceY>10) ? 'scroll' : 'no-scroll'
      this.app.scrollTop = distanceY

      this.event.emit('getDevice') 
   }
   heightfromwidth(value=.35){
      return parseInt(Math.min(this.app.width,1280)*value)
   }


   /* Restituisci il contenuto renderizzato del componente vue passato insieme alle sueproprieta */
   async renderVue(vueComponent, propsD){
      var emailDiv = document.createElement("div");
      emailDiv.id = "email";
      document.body.appendChild(emailDiv);

      this.emailProps= propsD
      const app= createApp(vueComponent, propsD)
      //const app= createApp(Object.assign(vueComponent, {propsData: propsD}))
      //const app = createSSRApp(Object.assign({}, vueComponent, {propsData: propsD}))
      for(var globalProp in this.vueAppInstance.config.globalProperties){
         app.config.globalProperties[globalProp]= this.vueAppInstance.config.globalProperties[globalProp]
      }
      app.mount('#email')
      //console.log(app)
      var html= app._container.innerHTML
      app.unmount()
      document.body.removeChild(emailDiv)
      //html = await renderToString(app)
      //const vm = new Vue( Object.assign({}, vueComponent, {propsData: propsD})).$mount()
      //return  vm.$el.innerHTML
      return html
   }

}
export default main
