import main from './main'

export class teras extends main{

   constructor (args) {
      super(args)
      this.name = 'www.vdlegal.it.2019'
      this.version = '1.0.1'
      this.urlsite = (process.env.NODE_ENV != 'development') ? document.location.origin + '/2019/' : ''
      this.page = {
         title: 'Home'
      }
      this.pagename= ""

      this.apiUrl = 'https://api.terasmm.it/201806/'
      this.mysql = {
         composer:'db',
         adapter:'',
         database:''
      }
      this.minWidthMobile= 760

      this.defaultPageTitle= "VDLegal"

      this.aree= [
         { link: "", cssClass: "compliance-privacy", nome: "Compliance Privacy", descrizione: "Adeguamento delle aziende alle normative sulla protezione dei dati personali (GDPR), lo studio offre consulenza personalizzata per garantire che le imprese rispettino tutte le disposizioni legali in materia di privacy." },
         { link: "", cssClass: "dpo", nome: "Incarichi di DPO (Data Protection Officer)", descrizione: "L'avvocato Devetag riveste il ruolo di DPO per numerose imprese, offrendo una supervisione continua delle politiche di protezione dei dati e garantendo il rispetto delle normative in materia." },
         { link: "", cssClass: "legal-tech", nome: "Legal Tech e Intelligenza Artificiale", descrizione: "Lo studio fornisce consulenza in materia di legal tech e di IA; fornendo consulenza su come implementare soluzioni tecnologiche innovative per migliorare l'efficienza dei processi legali e supportare le aziende nella trasformazione digitale." },
         { link: "", cssClass: "dlgs-231", nome: "D.Lgs. 231/01", descrizione: "Lo studio supporta le aziende nell’implementazione e nella gestione di modelli organizzativi conformi al D.Lgs. 231, prevenendo i rischi di responsabilità amministrativa e promuovendo una cultura aziendale etica e trasparente." },
         { link: "", cssClass: "esg", nome: "ESG (Environmental, Social, and Governance)", descrizione: "Lo studio supporta le aziende in materia di sostenibilità e governance aziendale, aiuta le imprese a integrare principi ESG nelle loro strategie operative, migliorando la loro reputazione e competitività sul mercato." },
         //{ cssClass: "", nome: "" }
      ]

      this.professionisti= [
         { nome: "Veronica", cognome: "Devetag Chalaupka", mail: "devetag@vdlegal.it", img: "veronica-devetag.png", ruolo: "Avvocato" },
         { nome: "Veronica", cognome: "Devetag", mail: "devetag@vdlegal.it", img: "placeholder-square.png", ruolo: "Avvocato" },
         { nome: "Veronica", cognome: "Devetag", mail: "devetag@vdlegal.it", img: "placeholder-square.png", ruolo: "Avvocato" },
      ]
      this.professionistaMain= this.professionisti[0]
      
      
      this.utente = {id:'',email:''}
      super.init()
      return this
   }

 
}
export default new teras
