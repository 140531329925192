import teras from '@/teras'
import aes from './aes'
class tStorage {
   constructor () {
   }
   
   save(key,value) {
      key = ((arguments.length>2 && arguments[2])  ? arguments[2] : teras.name) + '.'+ key
      localStorage.setItem(key,aes.encode(JSON.stringify(value)))
   }
   load(key) {
      key =  ((arguments.length>1 && arguments[1])  ? arguments[1] : teras.name) + '.'+ key
      return (localStorage.getItem(key) === null) ?  null : JSON.parse(aes.decode(localStorage.getItem(key)))
   }
   exist(key){
      key =  ((arguments.length>1 && arguments[1])  ? arguments[1] : teras.name) + '.'+ key
      return (localStorage.getItem(key) === null) ? false : true
   }
   remove(key){
      key =  ((arguments.length>1 && arguments[1])  ? arguments[1] : teras.name) + '.'+ key
      localStorage.removeItem(key)
   }

}
export default new tStorage
